<template>
  <div v-if="loading" class="flex justify-center w-full h-full items-center">
    <SpinnerColor2Vue />
  </div>

  <!-- error -->
  <div v-else-if="error_msg" class="con-vs-alert con-vs-alert-danger con-icon">
    <div class="vs-alert con-icon">
      <i class="vs-icon notranslate icon-scale icon-alert material-icons null">new_releases</i>
      <span>{{ error_msg }}</span>
    </div>
  </div>

  <div v-else class="page-print">
    <div class="title-page flex justify-between text-center items-center">
      <div>
        <feather-icon icon="PrinterIcon" svgClasses="h-4 w-4"></feather-icon>
        <h3 class="font-semibold mb-4 ml-3" style="display: inline-block; font-size: 1.125rem">
          {{ $t('print') }}
        </h3>
      </div>
      <div class="flex items-center"></div>
    </div>

    <div class="line"></div>

    <!-- id as param (printOrder/{id}) -->
    <div v-if="selected === ''" class="print">
      <div class="parent-print">
        <div class="header-print">
          <div class="right-header">
            <h3>المحافظة : {{ detail_of_order.government_name }}</h3>
            <h3>المنطقة : {{ detail_of_order.area }}</h3>
          </div>
          <div class="center-header">
            <div class="flex flex-col items-center">
              <img class="barcode" :src="detail_of_order.barcode" width="140" height="28" onerror="this.src=''" />
              <span class="font-semibold text-dark">{{ detail_of_order.code }}</span>
            </div>

            <h1 class="code">كود الطرد : {{ detail_of_order.code }}</h1>
          </div>
          <div class="left-header">
            <div class="logo text-center ml-auto" style="max-width: 200px">
              <img :src="logo" alt="Logo" width="104" height="23" onerror="this.src=''" />
              <p class="text-primary text-xs font-bold ml-auto text-center">{{ logoSubtitle }}</p>
            </div>
          </div>
        </div>
        <div class="footer-print">
          <table>
            <thead>
              <tr>
                <th>{{ 'الراسل' }} : {{ detail_of_order.client_name }}</th>
                <th>{{ 'المستلم' }} : {{ detail_of_order.receiver }}</th>
                <th>{{ 'الراسل الفرعي' }} : {{ detail_of_order.second_client || '-----------------' }}</th>
                <th>{{ 'االقيمة' }} : {{ detail_of_order.amount_to_be_collected }}</th>
              </tr>
            </thead>
          </table>
        </div>
        <div class="information">
          <div class="right-information">
            <p>{{ 'العنوان' }} : {{ detail_of_order.address }}</p>
            <p>{{ 'محتوي الطرد' }} : {{ detail_of_order.order_summary }}</p>
            <p>{{ 'الفرع المتوقع' }} : {{ detail_of_order.expected_branch }}</p>
          </div>
          <div class="left-information">
            <p>{{ 'التليفون' }} : {{ detail_of_order.phone1 }}</p>
            <p>{{ 'ملاحظات' }} : {{ detail_of_order.notes }}</p>
          </div>
        </div>
      </div>
    </div>

    <!-- multi selected orders to print (query:selected) -->
    <div v-if="print_order && print_order.length" class="print">
      <div
        v-for="(select, index) in print_order"
        :key="select.code || index" class="mt-6"
        :class="{ 'break-point': (index + 1) % 2 === 0 }"
      >
        <div class="parent-print" >
          <div class="header-print">
            <div class="right-header">
              <h3>{{ 'المحافظة' }} : {{ select.government }}</h3>
              <h3>{{ 'المنطقة' }} : {{ select.area }}</h3>
            </div>
            <div class="center-header">
              <div class="flex flex-col items-center">
                <img class="barcode" :src="select.bar_code" width="140" height="28" onerror="this.src=''" />
                <span class="font-semibold text-dark">{{ select.code }}</span>
              </div>

              <h1 class="code">{{ 'كود الطرد' }} : {{ select.code }}</h1>
            </div>
            <div class="left-header">
              <div class="logo text-center ml-auto" style="max-width: 200px">
                <img :src="logo" alt="Logo" width="104" height="23" onerror="this.src=''" />
                <p class="text-primary text-xs font-bold ml-auto text-center">{{ logoSubtitle }}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="footer-print">
          <table>
            <thead>
              <tr>
                <th>{{ 'الراسل' }} : {{ select.main_client }}</th>
                <th>{{ 'المستلم' }} : {{ select.receiver }}</th>
                <th>{{ 'الراسل الفرعي' }} : {{ select.second_client || '-----------------' }}</th>
                <th>{{ 'القيمة' }} : {{ select.amount_to_be_collected }}</th>
              </tr>
            </thead>
          </table>
        </div>

        <div class="information">
          <div class="right-information">
            <p>{{ 'العنوان' }} : {{ select.address }}</p>
            <p>{{ 'محتوي الطرد' }} : {{ select.order_summary }}</p>
            <p>{{ 'الفرع المتوقع' }} : {{ select.expected_branch }}</p>
          </div>
          <div class="left-information">
            <p>{{ 'التليفون' }} : {{ select.phone1 }}</p>
            <p>{{ 'ملاحظات' }} : {{ select.notes || '-----------------' }}</p>
          </div>
        </div>
      </div>

      <br v-if="(index + 1) % 2 === 0" clear="all" style="page-break-before: always" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SpinnerColor2Vue from '../components/table-components/SpinnerColor2.vue';

export default {
  data() {
    return {
      selected: '',
      loading: false,
      error_msg: '',
    };
  },
  computed: {
    ...mapGetters('dataList', ['print_order']),

    logo() {
      return '/logo.svg';
    },
    logoSubtitle() {
      return 'ادعم أهالينا في غزة بالتبرع من خلال مؤسسة مرسال الموثوقه عن طريق الخط الساخن 19340';
    },
  },
  methods: {
    ...mapActions('dataList', ['orderDetails', 'printOrder']),
    printNow() {
      this.loading = false;
      if (!this.error_msg) {
        setTimeout(() => {
          window.print();
        }, 1000);
      }
    },

    async fetchQrcodes(arrStr = '') {
      const formdata = new FormData();
      if (!arrStr) {
        return;
      }

      let arr = arrStr.split('|');
      arr = arr.slice(0, arr.length - 1);

      arr.forEach((code, codeIndex) => {
        formdata.append(`code[${codeIndex}]`, code);
      });

      try {
        const res = await this.printOrder(formdata);
        if (res.data.error_msg) {
          this.error_msg = res.data.error_msg;
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
  },

  components: { SpinnerColor2Vue },

  async created() {
    this.error_msg = '';
    this.loading = true;
    // reset old printed orders
    this.$store.commit('dataList/SET_PRINT_ORDER', []);

    if (this.$route.query.selected) {
      this.selected = this.$route.query.selected;
      await this.fetchQrcodes(this.selected);
    }

    this.printNow();
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/vuexy/_variables.scss';
.parent-print {
  margin: auto;
  border: 1px solid #9f9b9be0;
  padding: 17px 20px;
  border-radius: 4px;
  margin-bottom: 25px;
}
.break-point {
  page-break-after: always;
  page-break-inside: avoid;
}
.header-print {
  display: flex;
  justify-content: space-between;
}
.right-header,
.left-header {
  width: 50%;
}
.right-header h3 {
  font-size: 15px;
  font-weight: bold;
  line-height: 17px;
  margin-bottom: 8px;
  color: #000;
}
.center-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-width: 200px;
}
.left-header {
  text-align: right;
}
.logo {
  // margin-bottom: 35px;
  padding-top: 11px;
}
.code {
  color: #000;
  font-weight: bold;
  font-size: 18px;
  line-height: 1.5;
  text-decoration: unset;
  text-align: center;
}
.barcode {
  height: 28px;
  width: 140px;
}
.information {
  display: flex;
  justify-content: space-between;
}
.right-information {
  width: 60%;
}
.left-information {
  width: 40%;
}
.right-information p,
.left-information p {
  font-size: 12px;
  line-height: 12px;
  font-weight: bold;
  margin-bottom: 12px;
  color: #000;
  word-break: break-all;
}
.footer-print {
  margin-top: 20px;
  margin-bottom: 17px;
}
.footer-print table {
  width: 100%;
  border-collapse: collapse;
}
.footer-print table thead tr th {
  border: solid 2px rgba(112, 112, 112, 0.1);
  text-align: center;
  padding: 30px 8px;
  color: #212121;
  font-weight: 700;
}
.line {
  border-bottom: 0.03125rem solid #70707061;
  opacity: 50%;
  margin-bottom: 1.3rem;
}
.print {
  background: #fff;
  padding: 12px;
  border: 1px solid #70707061;
  border-radius: 4px;
}
</style>
<style lang="scss">
@media print {
  .vx-navbar-wrapper.nav-menu-wrapper,
  .title-page,
  .the-footer,
  .line {
    display: none !important;
  }
  .navbar-floating .vx-navbar-wrapper {
    display: none !important;
  }
  .main-ticket {
    display: none;
  }
  .print {
    padding: 0px;
    border-radius: none;
    border: none;
  }
}
</style>
